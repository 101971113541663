class Particle {
  constructor(props) {
    const {
      i: x,j: y,
      colors,
      ctx, wh, ww, mouse,
      radius
    } = props;
    this.radius = radius;
    this.ctx = ctx;
    this.x = Math.random() * ww;
    this.y = Math.random() * wh;
    this.dest = { x, y };
    this.mouse = mouse;
    this.r = Math.random() * 5 + 2;
    this.vx = (Math.random() - 0.5) * 20;
    this.vy = (Math.random() - 0.5) * 20;
    this.accX = 0;
    this.accY = 0;
    this.friction = Math.random() * 0.05 + 0.94;

    this.color = colors[Math.floor(Math.random() * 6)];
  }

  render() {
    this.accX = (this.dest.x - this.x) / 1000;
    this.accY = (this.dest.y - this.y) / 1000;
    this.vx += this.accX;
    this.vy += this.accY;
    this.vx *= this.friction;
    this.vy *= this.friction;

    this.x += this.vx;
    this.y += this.vy;

    this.ctx.fillStyle = this.color;
    this.ctx.beginPath();
    this.ctx.arc(this.x, this.y, this.r, Math.PI * 2, false);
    this.ctx.fill();

    var a = this.x - this.mouse.x;
    var b = this.y - this.mouse.y;

    var distance = Math.sqrt(a * a + b * b);
    if (distance < this.radius * 70) {
      this.accX = (this.x - this.mouse.x) / 100;
      this.accY = (this.y - this.mouse.y) / 100;
      this.vx += this.accX;
      this.vy += this.accY;
    }
  }
}

class BubblesText {
  constructor(canvas) {
    this.canvas = canvas;
    this.ctx = this.canvas.getContext('2d');
    this.particles = [];
    this.amount = 0; 
    this.mouse = { x: 0, y: 0 };
    this.radius = 1;

    //this.colors = ['#468966', '#FFF0A5', '#FFB03B', '#B64926', '#8E2800'];
    //this.colors = ['#ff5a5a', '#ff7c7c', '#ff9e9e', '#ffbebe', '#ffecec'];
    //this.colors = ['#E800BD', '#FF951B', '#0099D3', '#FFD950', '#752996', '#010101'];
    this.colors = ['#E800BD', '#FF951B', '#0099D3', '#FFD950', '#752996'];
    this.copy = {
      value: 'flim',
    };


    window.addEventListener('resize', this.initScene);
    window.addEventListener('mousemove', (e) => {
      this.mouse.x = e.clientX;
      this.mouse.y = e.clientY;
    });
    window.addEventListener('touchmove', (e) => {
      if (e.touches.length > 0) {
        this.mouse.x = e.touches[0].clientX;
        this.mouse.y = e.touches[0].clientY;
      }
    });
    window.addEventListener('click', () => {
      this.radius++;
      if (this.radius === 5) {
        this.radius = 0;
      }
    });
    window.addEventListener('touchend', () => {
      this.mouse.x = -9999;
      this.mouse.y = -9999;
    });

    this.initScene();
  }

  initScene(){
    if (!this.canvas) {
      return;
    }
    let ww = this.canvas.width = parseInt(window.innerWidth * 1);
    //let ww = this.canvas.width = parseInt(window.innerWidth * 0.5);
    let wh = this.canvas.height = parseInt(window.innerHeight * 0.6); //0.35

    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  
    //this.ctx.font = "bold "+(ww/3)+"px 'Roboto Condensed', sans-serif";
    this.ctx.font = "475px 'Roboto Condensed', sans-serif";
    this.ctx.textAlign = "center";
    this.ctx.fontWeight = "800";
    this.ctx.fillText(this.copy.value, ww/2, wh/1.15);
    //this.ctx.fillText(this.copy.value, ww/2, wh/1.2);
  
    var data  = this.ctx.getImageData(0, 0, ww, wh).data;
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.globalCompositeOperation = "screen";
  
    this.particles = [];
    for(var i=0;i<ww;i+=Math.round(ww/190)){
      for(var j=0;j<wh;j+=Math.round(ww/190)){
        if(data[ ((i + j*ww)*4) + 3] > 150){
          const props = {
            i,j,
            colors: this.colors,
            ctx: this.ctx,
            wh, ww, mouse: this.mouse,
            radius: this.radius
          }
          this.particles.push(new Particle(props));
        }
      }
    }
    this.amount = this.particles.length;
  }

  render() {
    requestAnimationFrame(this.render.bind(this));
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    for (var i = 0; i < this.amount; i++) {
      this.particles[i].render();
    }
  }
}


export default BubblesText;