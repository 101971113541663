import sync from 'framesync';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef } from 'react';
import ReactTextTransition, { presets } from 'react-text-transition';

import { useOnScreen } from '../../../hooks';
import { sections } from '../../../contants';
import BubblesText from './bubblesText';
import Context from '../../../Context';

import './home.scss';

const texts = [
  {
    title: 'We are',
    color: '#FCD552',
    amount: 3,
    paragraph:
      'a creativity studio that designs technological experiences to communicate.',
  },
  {
    title: 'We explore',
    color: '#FCD552',
    amount: 3,
    paragraph:
      'a world of possibilities to find new ways to connect with people.',
  },
  {
    title: 'We identify',
    color: '#FCD552',
    altColor: '#ff951b',
    amount: 4,
    paragraph:
      'stories and conceptualize then into catered experiences using emerging technologies.',
  },
  {
    title: 'We thrive',
    color: '#FCD552',
    amount: 2,
    invert: true,
    paragraph:
      "to build new perspectives on technology, it's funcionality and its relationship to humans.",
  },
];

const Home = () => {
  const context = React.useContext(Context);
  const { setSection } = context;
  const ref = useRef();
  const container = useRef();
  const { t } = useTranslation();
  const isVisible = useOnScreen(ref);
  const [idxTexts, setIdxTexts] = React.useState(0);
  const [timeIdx, setTimeIdx] = React.useState(0);
  useEffect(() => {
    if (isVisible) {
      setSection(sections.HOME);
    }
  }, [isVisible]);
  useEffect(() => {
    const bubleText = new BubblesText(container.current);
    bubleText.initScene();
    bubleText.render();
  }, []);
  useEffect(() => {
    if ((timeIdx % 100) + 1 === 100) {
      window.changing = true;
      setIdxTexts(prev => prev + 1);
      setTimeIdx(0);
      setTimeout(() => {
        window.changing = false;
      }, 1000);
    }
  }, [timeIdx]);
  useEffect(() => {
    sync.update(({ timestamp }) => {
      if (parseInt(timestamp) % 2 === 0) {
        !window.changing && setTimeIdx((prev) => prev + 0.25);
      }
    }, true);
  }, []);
  const goNextText = (idxT) => {
    window.changing = true;
    setIdxTexts(idxT);
    setTimeout(() => {
      window.changing = false;
    }, 1000);
  };
  return (
    <>
      <div id="home" ref={ref} className="f-block">
        <div className='canvas-cont'>
          <canvas ref={container}></canvas>
          <div>
            {t('art is evolving')}
          </div>
        </div>
        <section className="inline">
          <ReactTextTransition
            text={t(texts[idxTexts % texts.length].title)}
            springConfig={presets.gentle}
            className="big-text-transition"
            style={{ color: texts[idxTexts % texts.length].color }}
          />
          <ReactTextTransition
            text={t(texts[idxTexts % texts.length].paragraph)}
            springConfig={presets.gentle}
            className="normal-text-transition"
            //delay={100}
          />
          <div className="dot-cont">
            <div className="pagination">
              {texts.map((_, idx) => (
                <div
                  key={`dot-${idx}`}
                  onClick={() => goNextText(idx)}
                  className={`dot ${(idxTexts % texts.length) === idx ? 'active' : ''}`}
                />
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Home;
