import React, { useState, useEffect } from 'react';
import useHorizontal from '@oberon-amsterdam/horizontal/hook';
import Home from './components/sections/home';
import Hero from './components/sections/hero';
import Possibilities from './components/sections/possibilities';
import Contact from './components/sections/contact';

import Layout from './components/layout';
import Context from './Context';

import { sections } from './contants';

//import './i18n';

import './App.scss';

const sectionArray = Object.values(sections);

const App = () => {
  const [container, setContainer] = useState();
  const [section, setSection] = useState(sections.HOME);
  const [idxSection, setIdxSection] = useState(0);

  useHorizontal({ container, ignoreScroll: () => true });
  const handleScroll = (e) => {
    if (window.scrolling) return;
    window.scrolling = true;
    if (e.wheelDeltaY < 0) {
      setIdxSection((prev) => {
        if (prev === sectionArray.length - 1) {
          return prev;
        }
        return prev + 1;
      });
    } else {
      setIdxSection((prev) => {
        if (prev === 0) {
          return prev;
        }
        return prev - 1;
      });
    }
    setTimeout(() => {
      window.scrolling = false;
    }, 800);
  };
  useEffect(() => {
    window.addEventListener('wheel', handleScroll);
    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, []);
  const value = {
    section,
    setSection,
    setIdxSection,
  };
  useEffect(() => {
    const newSection = sectionArray[idxSection];
    setSection(newSection);
  }, [idxSection]);
  return (
    <Context.Provider value={value}>
      <Layout>
        <div className="container-hook" ref={(ref) => setContainer(ref)}>
          <Home />
          <Possibilities />
          <Hero />
          <Contact />
        </div>
      </Layout>
    </Context.Provider>
  );
};

export default App;
