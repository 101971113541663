import React from 'react';
import Header from './header';
import Dots from './dots';

import './header.scss';

const Layout = ({ children }) => {
  return (
    <>
      <Header navPosition="right" className="reveal-from-bottom" />
      {children}
    </>
  )
}

export default Layout;
