import React, { useState, useRef, useEffect } from 'react';
import { sections } from '../../contants';
import Context from '../../Context';
import { useTranslation } from 'react-i18next';

import './header.scss';

const Header = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {
  const { t } = useTranslation();
  const refHero = useRef();
  //const [prevSection, setPrevSection] = useState(sections.HOME);
  const refPossibilities = useRef();
  const refContact = useRef();
  const refHome = useRef();
  const context = React.useContext(Context);
  const { section, setSection, setIdxSection } = context;
  const [isActive, setIsactive] = useState(false);
  const [colorLink, setColorLink] = useState('#FFFFFF');
  const [pulse, setPulse] = useState();

  const nav = useRef(null);
  const hamburger = useRef(null);

  useEffect(() => {
    isActive && openMenu();
    document.addEventListener('keydown', keyPress);
    document.addEventListener('click', clickOutside);
    return () => {
      document.removeEventListener('keydown', keyPress);
      document.removeEventListener('click', clickOutside);
      closeMenu();
    };
  });

  useEffect(() => {
    /* if (section === sections.CONTACT) {
      setColorLink('#FFFFFF');
    }
    else if (section !== sections.HOME) {
      setColorLink('#bbb');
    } else {
      setColorLink('#FFFFFF');
    } */
    if (true) {
      window.scrolling = true;
      switch (section) {
        case sections.HOME:
          refHome.current.click();
          break;
        case sections.HERO:
          refHero.current.click();
          break;
        case sections.POSIBILITIES:
          refPossibilities.current.click();
          break;
        case sections.CONTACT:
          refContact.current.click();
          break;
        default:
          break;
      }
      setTimeout(() => window.scrolling = false, 800);
    }
  }, [section]);

  const openMenu = () => {
    document.body.classList.add('off-nav-is-active');
    nav.current.style.maxHeight = nav.current.scrollHeight + 'px';
    setIsactive(true);
  };

  const closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  };

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  };

  const clickOutside = (e) => {
    if (!nav.current) return;
    if (
      !isActive ||
      nav.current.contains(e.target) ||
      e.target === hamburger.current
    )
      return;
    closeMenu();
  };
  const getColor = () => {
    switch (section) {
      case sections.HERO:
        return '#402F42';//'#ff951b';
      case sections.POSIBILITIES:
        return '#402F42';//'#0099d3';
      case sections.CONTACT:
        return '#402F42';//'#717D86';
      default:
        return '#402F42';//'#f7f7f7';
    }
  };
  const classes = `
    site-header
    ${bottomOuterDivider && 'has-bottom-divider'},
    ${className}
  `;
  return (
    <header
      {...props}
      className={classes}
      // if section exist set getColor() else set transparent
      style={
        section !== sections.HOME
          ? { backgroundColor: getColor(), boxShadow: 'none' }
          : { boxShadow: 'none' }
      }
    >
      <div className="container">
        <div
          className={`site-header-inner ${
            bottomDivider && 'has-bottom-divider'
          }`}
        >
          <div className="header-nav brand m-0">
            <a
              ref={refHome}
              style={{ color: colorLink }}
              href="#home"
              className="custom-logo-link"
            >
              {section === sections.HOME ? 'flim' : 'flim'}
            </a>
          </div>
          {!hideNav && (
            <>
              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={isActive ? closeMenu : openMenu}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav
                ref={nav}
                className={`header-nav ${isActive && 'is-active'}`}
              >
                <div className="header-nav-inner">
                  <ul
                    className={`list-reset text-xs nav-bar ${
                      navPosition && `header-nav-${navPosition}`
                    }`}
                  >
                    <li>
                      <a
                        ref={refPossibilities}
                        style={{ color: colorLink }}
                        href="#posibility"
                        onClick={() => {
                          setPulse(sections.POSIBILITIES);
                          setTimeout(() => setPulse(), 1000);
                          //setSection(sections.POSIBILITIES);
                          setIdxSection(1);
                          closeMenu();
                        }}
                        className={`
                          ${section === sections.POSIBILITIES && 'bold'}
                          ${pulse === sections.POSIBILITIES && 'pulse'}
                        `}
                      >
                        {t('Possibilities')}
                      </a>
                    </li>
                    <li>
                      <a
                        ref={refHero}
                        style={{ color: colorLink }}
                        href="#info"
                        onClick={() => {
                          setPulse(sections.HERO);
                          setTimeout(() => setPulse(), 1000);
                          //setSection(sections.HERO);
                          setIdxSection(2);
                          closeMenu();
                        }}
                        className={`
                          ${section === sections.HERO && 'bold'}
                          ${pulse === sections.HERO && 'pulse'}
                        `}
                      >
                        {t('How we do it')}
                      </a>
                    </li>
                    <li>
                      <a
                        ref={refContact}
                        style={{ color: colorLink }}
                        href="#contact"
                        onClick={() => {
                          setPulse(sections.CONTACT);
                          setTimeout(() => setPulse(), 1000);
                          setSection(sections.CONTACT);
                          setIdxSection(3);
                          closeMenu();
                        }}
                        className={`
                          ${section === sections.CONTACT && 'bold'}
                          ${pulse === sections.CONTACT && 'pulse'}
                        `}
                      >
                        {t('Get it touch')}
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
