import React from 'react';
import Color from 'color';
import sync from 'framesync';
import { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { softShadows, OrbitControls } from '@react-three/drei';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import ReactTextTransition, { presets } from 'react-text-transition';
import { useTranslation } from 'react-i18next';

import Dots from '../../layout/dots';
import Context from '../../../Context';
import { useOnScreen } from '../../../hooks';
import { sections } from '../../../contants';
import AnimatedSphere from '../../shared/Sphere';

import './hero.scss';
import 'react-circular-progressbar/dist/styles.css';

softShadows();

const texts = [
  {
    title: 'We are',
    color: '#ff951b',
    amount: 3,
    paragraph:
      'a creativity studio that designs technological experiences to communicate.',
  },
  {
    title: 'We explore',
    color: '#ff951b',
    amount: 3,
    paragraph:
      'a world of possibilities to find new ways to connect with people.',
  },
  {
    title: 'We identify',
    color: '#ff951b',
    altColor: '#ff951b',
    amount: 4,
    paragraph:
      'stories and conceptualize then into catered experiences using emerging technologies.',
  },
  {
    title: 'We thrive',
    color: '#ff951b',
    amount: 2,
    invert: true,
    paragraph:
      "to build new perspectives on technology, it's funcionality and its relationship to humans.",
  },
];
//                  celeste    naranja    purpura    fucsia
const baseColors = ['#0099d3', '#ff951b', '#752996', '#dd00b4'];

const bridgeColors = 100;

const smoothColors = (colors = []) => {
  colors = colors.map((color) => new Color(color));
  const smoth = colors.reduce((acc, color, idx) => {
    const newColors = [];
    if (!acc[idx - 1]) {
      newColors.push(color);
    } else {
      let i = 1;
      while (i <= bridgeColors) {
        if (i === 1) {
          newColors.push(acc[acc.length - 1].mix(color, i / bridgeColors));
        } else {
          newColors.push(
            newColors[newColors.length - 1].mix(color, i / bridgeColors)
          );
        }
        i++;
      }
    }
    return [...acc, ...newColors];
  }, []);
  const finalSmooth = [];
  let i = 1;
  const lastColor = smoth[smoth.length - 1];
  const firstColor = smoth[0];
  while (i <= bridgeColors) {
    if (i === 1) {
      finalSmooth.push(lastColor.mix(firstColor, i / bridgeColors));
    } else {
      finalSmooth.push(
        finalSmooth[finalSmooth.length - 1].mix(firstColor, i / bridgeColors)
      );
    }
    i++;
  }

  return [...smoth, ...finalSmooth];
};

const Blob = (props) => {
  const { color } = props;
  return (
    <Canvas
      {...props}
      shadowMap
      camera={{ position: [-5, 2, 5], fov: 60 }}
      colorManagement
      className="canvas"
    >
      <ambientLight intensity={0.4} />
      <ambientLight intensity={0.1} position={[2, -5, -2]} />
      <directionalLight castShadow position={[-2, 5, 2]} />
      <OrbitControls autoRotate />
      <group>
        <Suspense fallback={null}>
          <AnimatedSphere color={color} />
        </Suspense>
      </group>
    </Canvas>
  );
};

const Home = () => {
  const { t } = useTranslation();
  const [idxTexts, setIdxTexts] = React.useState(0);
  const [colors, setColors] = React.useState([]);
  const [changing, setChanging] = React.useState(true);
  const [changeColor, setChangeColor] = React.useState();
  const [colorIdx, setColorIdx] = React.useState(0);
  const ref = React.useRef();
  const context = React.useContext(Context);
  const { setSection } = context;
  const isVisible = useOnScreen(ref);
  React.useEffect(() => {
    if (isVisible) {
      setSection(sections.HERO);
      /*  sync.update(({ timestamp }) => {
        if (parseInt(timestamp) % 8 === 0) {
          setColorIdx((prev) => prev + 1);
        }
      }, true); */
    }
  }, [isVisible]);
  React.useEffect(() => {
    if ((colorIdx % 100) + 1 === 100) {
      window.changing = true;
      setColorIdx(0);
      setIdxTexts((prev) => prev + 1);
      setTimeout(() => {
        window.changing = false;
      }, 1000);
    }
  }, [colorIdx]);
  const goNextText = () => {
    //setChanging(true);
    window.changing = true;
    setColorIdx(0);
    setIdxTexts((prev) => prev + 1);
    setTimeout(() => {
      window.changing = false;
    }, 1000);
  };
  /* React.useEffect(() => {
    setInterval(() => {
      if (!window.changing) {
        setColorIdx((prev) => prev + 1);
      }
    }, 150);
  }, []); */
  React.useEffect(() => {
    sync.update(({ timestamp }) => {
      if (parseInt(timestamp) % 2 === 0) {
        !window.changingPos && setColorIdx((prev) => prev + 0.25);
      }
    }, true);
  }, []);
  /* React.useEffect(() => {
    setColors(smoothColors(baseColors).map((color) => color.hex()));
    setInterval(() => {
      setIdxTexts((prev) => {
        return prev + 1;
      });
    }, 6000);
  }, []); */
  return (
    <div ref={ref} className="f-block" id="info" name="info">
      <Dots amount={2} />
      <div className="info-container hero">
        <section className="inline">
          <ReactTextTransition
            text={t(texts[idxTexts % texts.length].title)}
            springConfig={presets.gentle}
            className="big"
            style={{ color: texts[idxTexts % texts.length].color }}
          />
          <ReactTextTransition
            text={t(texts[idxTexts % texts.length].paragraph)}
            springConfig={presets.gentle}
            delay={100}
          />
          <div onClick={() => goNextText()} className="circular-cont">
            <div className="progress-circle">
              <CircularProgressbar
                value={(colorIdx % 100) + 1}
                styles={buildStyles({
                  pathColor: '#ff951b',
                  trailColor: '#fcf8f8',
                })}
              />
            </div>
            <div>{t('Next')}</div>
          </div>
        </section>
        <div
          className="blob-container"
          onMouseEnter={() => setChangeColor(false)}
          onMouseLeave={() => setChangeColor(false)}
        >
          <Blob
            color={
              changeColor ? colors[colorIdx % colors.length] : texts[0].color
            }
          />
        </div>
      </div>
    </div>
  );
};

const Work = () => (
  <div className="f-block" id="info" style={{ color: '#fff' }}>
    Work in progress
  </div> 
)

export default Work;
