import React from 'react';
import sync from 'framesync';
import Tilt from 'react-parallax-tilt';
import { useTranslation } from 'react-i18next';
import ReactTextTransition, { presets } from 'react-text-transition';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import Dots from '../../layout/dots';
import Context from '../../../Context';
import { useOnScreen } from '../../../hooks';
import { sections } from '../../../contants';

import './possibilities.scss';

const texts = [
  {
    title: 'TECH EXPERIENCES TO COMMUNICATE',
    color: '#0099d3',
    altColor: '#3C98A7',
    amount: 10,
    paragraph:
      'Desing experiences and solutions with technology catered to your needs, so that you revolutionize the way in wich you connect with people.',
    img: require('./../../../img/ar.png'),
  },
  {
    title: 'STORIES FROM YOUR DATA',
    color: '#0099d3',
    altColor: '#013447',
    amount: 15,
    paragraph:
      'Questions how your users experience and interact with information. We work with big data and ML to desing, prototype and deliver new ways of seeing data.',
    img: require('./../../../img/data.png'),
  },
  {
    title: 'FLIM EXPERIMENTAL',
    color: '#0099d3',
    altColor: '#9E28A3',
    amount: 10,
    paragraph:
      'We conceptualize processes of problem-solving into communicate experiments.',
    img: require('./../../../img/exp1.png'),
  },
];

const Retrieve = () => {
  const context = React.useContext(Context);
  const { setSection } = context;
  const ref = React.useRef();
  const imgTilt = React.useRef();
  const { t } = useTranslation();
  const isVisible = useOnScreen(ref);
  const [colorIdx, setColorIdx] = React.useState(0);
  const [idxTexts, setIdxTexts] = React.useState(0);
  const [innerGlare, setInnerGlare] = React.useState(true);
  const [tiltAngleYManual, setTiltAnglYManual] = React.useState(-10);
  React.useEffect(() => {
    if (isVisible) {
      setSection(sections.POSIBILITIES);
    }
  }, [isVisible]);
  React.useEffect(() => {
    if ((colorIdx % 100) + 1 === 100) {
      window.changingPos = true;
      setColorIdx(0);
      setIdxTexts((prev) => prev + 1);
      setTimeout(() => {
        window.changingPos = false;
      }, 1000);
    }
  }, [colorIdx]);
  React.useEffect(() => {
    sync.update(({ timestamp }) => {
      if (parseInt(timestamp) % 2 === 0) {
        setTiltAnglYManual((prev) => prev + 0.25);
        !window.changingPos && setColorIdx((prev) => prev + 0.25);
      }
    }, true);
  }, []);
  const goNextText = () => {
    //setChanging(true);
    window.changingPos = true;
    setTimeout(() => {
      setColorIdx(0);
      setIdxTexts((prev) => prev + 1);
      setTimeout(() => {
        window.changingPos = false;
      }, 1000);
    }, 250);
  };
  return (
    <div ref={ref} className="f-block" id="posibility">
      <Dots amount={2} />
      <div className="info-container hero">
        <section className="inline">
          <ReactTextTransition
            text={t(texts[idxTexts % texts.length].title)}
            springConfig={presets.gentle}
            className="big"
            style={{ color: texts[idxTexts % texts.length].color }}
          />
          <ReactTextTransition
            text={t(texts[idxTexts % texts.length].paragraph)}
            springConfig={presets.gentle}
            delay={100}
          />
          <div onClick={() => goNextText()} className="circular-cont">
            <div className="progress-circle">
              <CircularProgressbar
                value={(colorIdx % 100) + 1}
                styles={buildStyles({
                  pathColor: '#0099d3',
                  trailColor: '#fcf8f8',
                })}
              />
            </div>
            <div>{t('Next')}</div>
          </div>
        </section>
        <div
          onMouseEnter={() => setInnerGlare(false)}
          onMouseLeave={() => setInnerGlare(true)}>
          <Tilt
            tiltReverse
            tiltMaxAngleY={8}
            tiltMaxAngleX={10}>
            <Tilt
              tiltReverse
              ref={imgTilt}
              transitionSpeed={700}
              glareEnable={innerGlare}
              tiltAngleYManual={
                innerGlare ? Math.sin(tiltAngleYManual / 6.5) * 10 : null
              }
              tiltAngleXManual={
                innerGlare ? Math.cos(tiltAngleYManual / 6.5) * 8 : null
              }>
              <img
                className={window.changingPos ? 'img-change' : ''}
                src={texts[idxTexts % texts.length].img}
                alt=""
              />
            </Tilt>
          </Tilt>
        </div>
      </div>
    </div>
  );
};

const Work = () => (
  <div className="f-block" id="posibility" style={{ color: '#fff' }}>
    Work in progress
  </div> 
)

export default Work;
