import React from 'react';
import { useTranslation } from 'react-i18next';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';

import Context from '../../../Context';
import { sections } from '../../../contants';
import { useOnScreen } from '../../../hooks';

import './contact.scss';

const texts = [
  {
    title: "LET'S TALK",
    color: '#0099D3',
    paragraph:
      'Book a free consult with our team to talk about a world of possibilities for you and your brand',
  },
  {
    title: 'ANALISYS & CONCEPTUALIZATION',
    color: '#FF951B',
    paragraph:
      'Book a free consult with our team to talk about a world of possibilities for you and your brand',
  },
  {
    title: 'DEPLOY',
    color: '#E800BD',
    paragraph:
      'Book a free consult with our team to talk about a world of possibilities for you and your brand',
  },
];

const Contact = () => {
  const ref = React.useRef();
  const { t } = useTranslation();
  const context = React.useContext(Context);
  const { setSection } = context;
  const isVisible = useOnScreen(ref);
  React.useEffect(() => {
    if (isVisible) {
      setSection(sections.CONTACT);
    }
  }, [isVisible]);
  return (
    <div ref={ref} className="f-block" id="contact">
      <div className="f-block-content hero">
        <div className="l-block">{t('We would love hearing from you')}</div>
        <div className="r-block">
          <div className="text-contact">{t('Contact Us')}</div>
          <div>
            <FontAwesomeIcon icon={faEnvelope} />
            <a href="mailto:hello@flim.tech">hello@flim.tech</a>
          </div>
          <div>
            <FontAwesomeIcon icon={faTwitter} />
            <a
              href="https://twitter.com/flimtech"
              target="_blank"
              rel="noopener noreferrer"
            >
              @flimtech
            </a>
          </div>
          <div>
            <FontAwesomeIcon icon={faInstagram} />
            <a
              href="https://www.instagram.com/flimtech/"
              target="_blank"
              rel="noopener noreferrer"
            >
              @flimtech
            </a>
          </div>
        </div>
      </div>
      <footer>
        <div className='line-mid'/>
        <div>
          {t(
            'All rights reserved Flim  Studio 2022. Design: Flim Studio / Web: Enmanuel Magallanes'
          )}
          </div>
        <div className='line-mid'/>
      </footer>
    </div>
  );
};

/*
<div ref={ref} className="f-block" id="contact">
      <Dots amount={10} />
      <div className="f-block-content hero">
        <div className="info-container">
          {texts.map(({ color, paragraph, title }, index) => (
            <section className="inline" key={`id=${index}`}>
              <div className="big" style={{ color }}>
                {t(title)}
              </div>
              <div>{t(paragraph)}</div>
            </section>
          ))}
        </div>
        <div className="footer">
          <div className='text-mail'>
            {t('Mail us ')}<Emoji text=":smile:" />
          </div>
          <div className="social-media">
            <div>
              <FontAwesomeIcon icon={faEnvelope} />
              <a href="mailto:hello@flim.tech">hello@flim.tech</a>
            </div>
            <div>
              <FontAwesomeIcon icon={faTwitter} />
              <a
                href="https://twitter.com/flimtech"
                target="_blank"
                rel="noopener noreferrer"
              >
                @flimtech
              </a>
            </div>
            <div>
              <FontAwesomeIcon icon={faInstagram} />
              <a
                href="https://www.instagram.com/flimtech/"
                target="_blank"
                rel="noopener noreferrer"
              >
                @flimtech
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
*/

export default Contact;
